<template>
    <v-card tile class="mt-4 ml-5" max-width="90%">
        <div id="globalUsersDistributionCharts" :style="{width: '1200px', height: '680px'}" class="py-5 mx-auto" color="blue" elevation="12" max-width="calc(100% - 30px)"></div>
    </v-card>
</template>
<script>
    import Vue from 'vue'
    import config from '../config';
    import echarts from 'echarts';
    export default {
        data() {
            return {
                locationMapData: []
            }
        },
        props:{

        },
        mounted() {
            this.getLocationMapData();
        },
        computed: {

        },
        components: {

        },
        watch: {

        },
        methods: {
            drawLine() {
                // 基于准备好的dom，初始化echarts实例
                let globalUsersDistributionCharts = this.$echarts.init(document.getElementById('globalUsersDistributionCharts'))
                // 绘制图表
                globalUsersDistributionCharts.setOption({
                    backgroundColor: new echarts.graphic.RadialGradient(0.5, 0.5, 0.4, [{
                        offset: 0,
                        color: '#4b5769'
                    }]),
                    // 标题
                    title: {
                        text: 'Global Users Distribution Charts',
                        left: 'center',
                        top: 5,
                        itemGap: 0,
                        textStyle: {
                            color: '#eee'
                        },
                        z: 200
                    },
                    grid: [{
                        show: true,
                        left: 0,
                        right: 0,
                        top: '100%',
                        bottom: 0,
                        borderColor: 'transparent',
                        backgroundColor: '#404a59',
                        z: 99
                    }, {
                        show: true,
                        left: 0,
                        right: 0,
                        top: 0,
                        height: 28,
                        borderColor: 'transparent',
                        backgroundColor: '#404a59',
                        z: 199
                    }],
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                            return params.data.city + ', ' + params.data.region + ', ' + params.data.country + ' : ' + params.data.quantity;
                        }
                    },
                    toolbox: {
                        show: true,
                        left: 'right',
                        iconStyle: {
                            normal: {
                                borderColor: '#ddd'
                            }
                        },
                        feature: {
                        },
                        z: 202
                    },
                    brush: {
                        geoIndex: 0,
                        brushLink: 'all',
                        inBrush: {
                            opacity: 1,
                            symbolSize: 14
                        },
                        outOfBrush: {
                            color: '#000',
                            opacity: 0.2
                        },
                        z: 10
                    },
                    geo: {
                        map: 'world',
                        silent: true,
                        emphasis: {
                            label: {
                                show: false,
                                areaColor: '#eee'
                            }
                        },
                        itemStyle: {
                            borderWidth: 0.2,
                            borderColor: '#404a59'
                        },
                        left: '10%',
                        top: 40,
                        bottom: '10%',
                        right: '10%',
                        roam: true
                    },
                    series: [
                        {
                            name: 'Prices and Earnings 2020',
                            type: 'scatter',
                            coordinateSystem: 'geo',
                            symbolSize: 8,
                            data: this.locationMapData,
                            activeOpacity: 1,
                            label: {
                                formatter: '{b}',
                                position: 'right',
                                show: false
                            },
                            symbolSize: 10,
                            itemStyle: {
                                borderColor: '#fff',
                                color: '#577ceb',
                            },
                            emphasis: {
                                label: {
                                    show: false
                                }
                            }
                        }
                    ]
                });
            },
            getLocationMapData() {
                let api = config.baseUrl + '/admin/private/locationMap';
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        // 成功
                        this.locationMapData = data.result;
                        this.drawLine();
                    } else {
                        this.locationMapData = [];
                    }
                }, error => {
                    this.locationMapData = [];
                });

                // var mapData = [];
                // mapData.push({
                //     city: 'Shanghai',
                //     region: 'Shanghai',
                //     country: 'China',
                //     quantity: 15,
                //     value: [121.4012, 31.0449]
                // },
                // {
                //     city: 'New York',
                //     region: 'New York',
                //     country: 'United States',
                //     quantity: 6,
                //     value: [-121.886, 37.3382]
                // });
            }
        }
    }
</script>
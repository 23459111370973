<template>
    <div>
        <v-container fluid class="grid-list-xl">
            <v-row v-if="user.token">
                <!-- 第一行 -->
                <v-col cols="12">
                    <v-row justify="center">
                        <!-- 第一个 信号总数 -->
                        <v-col cols="3">
                            <!-- 测试按钮 -->
                            <!-- <v-btn @click="getLedger()">测试按钮</v-btn> -->
                            <v-card tile class="v-card--material-stats">
                                <v-card tile class="v-offset pa-4 theme--dark green elevation-8 ml-5"
                                    style="top: -24px; margin-bottom: -24px;">
                                    <v-icon class="theme--dark" x-large>fas fa-satellite-dish</v-icon>
                                </v-card>
                                <span class="mr-5 mt-5 grey--text"> Total Signals </span>
                                <v-card-text class="text-right">
                                    <router-link to="/signals" class="del-underline">
                                        <h1 class="font-45 grey--text mr-5">
                                            <!-- {{ common.formatNum(totalSignalsElements) }} -->
                                            <count-to :start-val='0' :end-val='totalSignalsElements' :duration='1200' :decimals='0' separator=','></count-to>
                                        </h1>
                                    </router-link>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-card-actions class="v-card__actions">
                                        <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                        <span> Last updated Date </span>
                                        <v-spacer></v-spacer>
                                        <span>{{common.dateFormat(signalLastTime)}}</span>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- 第二个 用户总数 -->
                        <v-col cols="3">
                            <v-card tile class="v-card--material-stats">
                                <v-card tile class="v-offset pa-4 theme--dark blue elevation-8 ml-5"
                                    style="top: -24px; margin-bottom: -24px;">
                                    <v-icon class="theme--dark" x-large>fas fa-user
                                    </v-icon>
                                </v-card>
                                <span class="mr-5 mt-5 grey--text">Total Users</span>
                                <v-card-text class="text-right">
                                    <router-link to="/user" class="del-underline">
                                        <h1 class="font-45 grey--text mr-5">
                                            <!-- {{ common.formatNum(totalUsersElements) }} -->
                                            <count-to :start-val='0' :end-val='totalUsersElements' :duration='1200' :decimals='0' separator=','></count-to>
                                        </h1>
                                    </router-link>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-card-actions class="v-card__actions">
                                        <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                        <span> Last joined Date </span>
                                        <v-spacer></v-spacer>
                                        <span>{{common.dateFormat(userLastTime)}}</span>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- 第三个 访问数量 -->
                        <v-col cols="3">
                            <v-card tile class="v-card--material-stats">
                                <v-card tile class="v-offset pa-4 theme--dark black elevation-8 ml-5" style="top: -24px; margin-bottom: -24px;">
                                    <v-icon class="theme--dark" x-large>fas fa-book-reader
                                    </v-icon>
                                </v-card>
                                <span class="mr-5 mt-5 grey--text">Total visits</span>
                                <v-card-text class="text-right">
                                    <router-link to="/visit" class="del-underline">
                                        <h1 class="font-45 grey--text mr-5">
                                            <!-- {{ common.formatNum(totalVisitCount) }} -->
                                            <count-to :start-val='0' :end-val='totalVisitCount' :duration='1200' :decimals='0' separator=','></count-to>
                                        </h1>
                                    </router-link>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-card-actions class="v-card__actions">
                                        <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                        <span>Today Visits</span>
                                        <v-spacer></v-spacer>
                                        <span>{{ todayVisitCount }}</span>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- 第二行 -->
                    <v-row justify="center" class="mt-5 ml-5">
                        <!-- 第一个 最近访问量 -->
                        <!-- <v-flex sm6 md12 xs12 md6 lg5 class="ml-5">
                            <v-card class="mt-4 ml-5" max-width="600">
                                <v-sheet class="v-sheet--offset mx-auto" color="blue" elevation="12" max-width="calc(100% - 30px)">
                                    <v-sparkline :labels="dateList" :value="volumeList" color="white" line-width="2" padding="16" stroke-linecap="round" :auto-draw="autoDraw" :auto-draw-duration="3500"></v-sparkline>
                                </v-sheet>
                                <v-card-text class="pt-0">
                                    <span class="title font-weight-light mb-2">Today Visits 
                                        <router-link to="/visit" class="del-underline grey--text">
                                        <span class="font-28">{{todayVisitCount}}</span>
                                        </router-link>
                                    </span>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-icon class="mr-2" small> mdi-clock </v-icon>
                                    <span class="caption grey--text font-weight-light">Last Update Time</span>&nbsp;&nbsp;
                                    <span>{{lastVisitUpdateTime}}</span>
                                </v-card-text>
                            </v-card>
                        </v-flex> -->
                        <v-col cols="10" class="ml-5">
                            <v-card tile class="mt-4 ml-5" max-width="90%" height="435">
                                <!-- <v-sheet class="v-sheet--offset mx-auto" color="blue" elevation="12" max-width="calc(100% - 30px)">
                                    <v-sparkline :labels="dateList" :value="volumeList" color="white" line-width="2" padding="16" stroke-linecap="round" :auto-draw="autoDraw" :auto-draw-duration="3500"></v-sparkline>
                                </v-sheet> -->
                                <app-charts :size="size"></app-charts>
                                <v-card-text class="pt-0">
                                    <v-row class="font-20 font-weight-light">
                                        <v-col>
                                            Today Visits 
                                        <router-link to="/visit" class="del-underline grey--text">
                                        <span class="font-28">
                                            <!-- {{todayVisitCount}} -->
                                            <count-to :start-val='0' :end-val='todayVisitCount' :duration='1200' :decimals='0' separator=','></count-to>
                                        </span>
                                        </router-link>
                                        </v-col>
                                        <v-col  class="text-right">
                                            <v-btn tile class="mr-3 blue white--text" @click="size=7">7</v-btn>
                                            <v-btn tile class="mr-3 blue white--text" @click="size=15">15</v-btn>
                                            <v-btn tile class="mr-3 blue white--text" @click="size=30">30</v-btn>
                                        </v-col>
                                    </v-row>
                                    <hr class="v-divider theme--light mb-3">
                                    <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                    <span class="font-14 grey--text font-weight-light">Last Update Time</span>&nbsp;&nbsp;
                                    <span>{{lastVisitUpdateTime}}</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="10" class="ml-5">
                            <global-users-distribution-charts></global-users-distribution-charts>
                        </v-col>
                        <!-- 第二个 -->
                        <!-- <v-flex sm6 md12 xs12 md6 lg3>
                            <v-card class="v-card--material-stats">
                                <v-card class="v-offset pa-4 theme--dark green elevation-10 ml-5"
                                    style="top: -24px; margin-bottom: -24px;">
                                    <v-icon class="theme--dark" style="font-size: 40px;">fas fa-satellite-dish
                                    </v-icon>
                                </v-card>
                                <span class="mr-5 mt-5 grey--text">--</span>
                                <v-card-text class="text-right">
                                    <router-link to="/" class="del-underline">
                                        <h1 class="display-2 grey--text mr-5">
                                            {{totalElements==0? '--':totalElements }}<small></small></h1>
                                    </router-link>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-card-actions class="v-card__actions">
                                        <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                        <span>xxxxx</span>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-flex> -->
                        <!-- 第三个 -->
                        <!-- <v-flex sm6 md12 xs12 md6 lg3>
                            <v-card class="v-card--material-stats">
                                <v-card class="v-offset pa-4 theme--dark green elevation-10 ml-5"
                                    style="top: -24px; margin-bottom: -24px;">
                                    <v-icon class="theme--dark" style="font-size: 40px;">fas fa-satellite-dish
                                    </v-icon>
                                </v-card>
                                <span class="mr-5 mt-5 grey--text">--</span>
                                <v-card-text class="text-right">
                                    <router-link to="/" class="del-underline">
                                        <h1 class="display-2 grey--text mr-5">
                                            {{totalElements==0? '--':totalElements }}<small></small></h1>
                                    </router-link>
                                    <hr class="v-divider theme--light mt-2">
                                    <v-card-actions class="v-card__actions">
                                        <v-icon class="mr-2">far fa-calendar-alt</v-icon>
                                        <span>xxxxx</span>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-flex> -->
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    import GlobalUsersDistributionCharts from '../../components/GlobalUsersDistributionCharts.vue';
    name: "home";
    export default {
        data() {
            return {
                totalSignalsElements: 0,
                signalLastTime: null,
                totalUsersElements: 0,
                userLastTime: null,
                totalElements: 0,
                totalVisitCount: 0,
                todayVisitCount: 0,
                dateList:[],
                volumeList:[],
                lastVisitUpdateTime:null,
                autoDraw:false,
                size:30,
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        components:{
            GlobalUsersDistributionCharts
        },
        watch: {
            
        },
        mounted() {
            if (Number(localStorage.getItem("iobots-admin-expires")) - ((new Date()).getTime()) > 0) {
                // localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 30 )
                localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 60 * 60 * 2)
            };
            this.getSignalCount();
            this.getTotalUsers();
            this.getSignalLastTime();
            this.getUserLastTime();
            this.getTotalVisitsCount();
            this.getTodayVisitsCount();
            this.getLastVisitUpdateTime();
        },
        created() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
        },
        methods: {
            // 获取信号数量
            getSignalCount() {
                let api = config.baseUrl + '/signal/admin/private/getSignalCount'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.totalSignalsElements = response.data.result;
                        } else {

                        }
                    },
                )
            },
            // 获取最后信号更新时间
            getSignalLastTime() {
                let api = config.baseUrl + '/signal/admin/private/getLastSignalUpdated'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.signalLastTime = response.data.result;
                        } else {

                        }
                    },
                )
            },
            // 获取用户数量
            getTotalUsers() {
                let api = config.baseUrl + '/user/private/getTotalUsers'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.totalUsersElements = response.data.result;
                        } else {

                        }
                    },
                )
            },
            // 获取最后更新时间
            getLastVisitUpdateTime(){
                var now = new Date();
                var year = now.getFullYear();   // 得到年
                var month = now.getMonth() + 1 >= 10 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1) ;     // 得到月份
                var date = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();       // 得到日期
                var hour = now.getHours();      // 得到小时
                var minu = now.getMinutes() >= 0 && now.getMinutes() < 30 ? 0 : 3;    // 得到分钟
                this.lastVisitUpdateTime = month +"/"+date+"/"+year+" "+hour+":"+minu+"0" 
            },
            // 获取用户最后加入更新时间
            getUserLastTime() {
                let api = config.baseUrl + '/user/private/getUserLastTime'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.userLastTime = response.data.result;
                        } else {

                        }
                    },
                )
            },
            // 获取总访问量
            getTotalVisitsCount() {
                let api = config.baseUrl + '/visit/private/getTotalVisitsCount'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.totalVisitCount = response.data.result;
                        } else {

                        }
                    },
                )
            },
            // 获取今日访问量
            getTodayVisitsCount() {
                let api = config.baseUrl + '/visit/private/getTodayVisitsCount'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.todayVisitCount = response.data.result;
                        } else {

                        }
                    },
                )
            },
            getLedger(){
                let api = config.baseUrl + '/exchange/okex/futures/private/getLedger/XRP-USD-SWAP'
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                        } else {

                        }
                    },
                )
            }
            // 时间格式化
            // dateFormat: function (time) {
            //     var date = new Date(time);
            //     var year = date.getFullYear();
            //     /* 在日期格式中，月份是从0开始的，因此要加0
            //      * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
            //      * */
            //     var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            //     var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            //     // 拼接
            //     return (month + "/" + day + "/" + year);
            // },
        }
    }
</script>

<style>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }
</style>
import { render, staticRenderFns } from "./GlobalUsersDistributionCharts.vue?vue&type=template&id=4538ced8&"
import script from "./GlobalUsersDistributionCharts.vue?vue&type=script&lang=js&"
export * from "./GlobalUsersDistributionCharts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/_vuetify-loader@1.7.2@vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
